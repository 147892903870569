import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";


const threeInRow = (index) => (index % 5) <= 2;

const tileStyle = (image) => {
  return {
    background: 'url(' + image + ') no-repeat 50% 50%',
    backgroundSize: 'cover',
    position: 'relative',
  }
};

const CaseStudyList = ({ data }) => (
  <section className="case-study-list">



    <div className="container">
      <div className="columns">
        <div className="column is-offset-1">
          <h1 className='h1'>Work</h1>
        </div>
       </div>

      <div className="columns is-multiline">
    {data.allMarkdownRemark.edges.map((c, i) => (

      <div key={i} className={`column ${threeInRow(i) ? 'is-4 three-row' : 'is-6 two-row'}`}>
          <div className="case-study-tile img__wrap" style={tileStyle(c.node.frontmatter.tile.childImageSharp.original.src)}>
            <Link className="" to={c.node.fields.slug}>
              <div className="img__wrap">

                <div className="img__description_layer">
                  <h2 className="img__description">{c.node.frontmatter.title}</h2>
                </div>
              </div>
            </Link>
          </div>


      </div>
    ))}
    </div>
    </div>
  </section>
);

export default props => (
  <StaticQuery
    query={graphql`
      query CaseStudies {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "case-study-page" } } },
          sort: { order: ASC, fields: [frontmatter___order]},
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                tile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
                order
              }
            }
          }
        }
      }
    `}
    render={data => <CaseStudyList data={data} {...props} />}
  />
);
